import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

import contactStyles from "./contact.module.scss"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>Let's talk!</h1>
    </Layout>
  )
}

export default ContactPage
